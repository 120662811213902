// < 50% = red
// 50 - 89 = orange
// >= 90 = vert

function ProgressColor(data) {
  
  var status = '';

  if (data < 50) {
    status = 'danger'
  } else if (data >= 50 && data < 90) {
    status = 'warning'
  } else if (data >= 90) {
    status = 'success'
  }

  return status;
}

export default ProgressColor;