import VerticalLayout from "../common/verticalLayout";
import { useEffect } from 'react';


function Layout(props: any) {
  useEffect(() => {
    const cookie = localStorage.getItem('Darkmode');
    if (cookie) {
      document.body.dataset.sidebar = (JSON.parse(cookie)? 'dark' : 'light');
    }
  }, [])

  return <VerticalLayout>{props.children}</VerticalLayout>;
}

export default Layout;
