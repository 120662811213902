import React from "react";
import ReactDOM from "react-dom";
import RootNavigator from "./Routes";
import reportWebVitals from "./reportWebVitals";
import * as serviceWorker from './serviceWorker';
import "./assets/scss/theme.scss";
import "./assets/scss/custom.scss"

ReactDOM.render(
  <React.StrictMode>
    <RootNavigator />
  </React.StrictMode>,
  document.getElementById("KIS©")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
serviceWorker.register();
