import React, { useState } from "react";
import { axiosClient } from "../../../../helpers";
import { Link } from "react-router-dom";
import { Credentials } from "../../../../helpers";
import { Alerts } from "../../../../helpers";
import { withTranslation } from "react-i18next";

import {
  Card,
  CardBody,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";


function Login(props) {
  const logo = require('../../../../assets/images/logo512.png').default;
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [displayAlert, setDisplayAlert] = useState(false);
  const [displayAlertType, setDisplayAlertType] = useState('');
  const [alertText, setAlertText] = useState('');

  function submitLogin(e) {
    e.preventDefault();
    setLoading(true);

    axiosClient.post('/ex/sign_in',
      {
        email: email,
        password: password
      }
    )
      .then((response) => {
        if (response.data.data.position === 5) {
          setDisplayAlertType('error');
          setAlertText(props.t("You are not on the right url. Go to commercial.obo.getkis.io"));
          setDisplayAlert(true);
          setLoading(false);
        } else {
          const token = response.headers['access-token'];
          const uid = response.headers['uid'];
          const client = response.headers['client'];

          const data = {
            'header': {
              'uid': uid,
              'token': token,
              'client' : client
            },
            'body': response.data
          };

          const action = 'set';

          Credentials(action, data);
        }
      })
      .catch(() => {
        setAlertText(props.t("Your email or password seems to be incorrect. Please try again."));
        setDisplayAlertType('error');
        setDisplayAlert(true);
        setLoading(false);
      })
  }
  
  function renderAlert() {
    if (displayAlert) {
      return Alerts(displayAlertType, alertText);
    }
  }

  function renderButton() {
    const spin = <div className="spinner-border text-primary mx-auto" role="status" />
    const btn = <button className="btn btn-primary btn-block waves-effect waves-light"
                  type="submit"
                >
                  {props.t("Log in")}
                </button>

    return loading ? spin : btn;
  }

  return (
    <React.Fragment>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <div className="bg-primary">
                  <Row>
                    <Col className="col-7">
                      <div className="text-primary p-4">
                        <h5 className="text-primary text-dark">
                          {props.t("Mise à jour des stocks YESSS")}
                        </h5>

                        <p className="text-dark">
                          {props.t("Please log in to proceed")}
                        </p>
                      </div>
                    </Col>

                    <Col className="col-5 align-self-center">
                      <img src={logo} alt="" className="img-fluid" />
                    </Col>
                  </Row>
                </div>

                <CardBody className="pt-0">
                  <div className="auth-logo">
                    <Link to="/" className="auth-logo-light">
                      <div className="avatar-md profile-user-wid mb-4">
                        <span className="avatar-title rounded-circle bg-light">
                          <img
                            src={require('../../../../assets/images/logo512.png')}
                            alt=""
                            className="rounded-circle"
                            height="34"
                          />
                        </span>
                      </div>
                    </Link>
                  </div>

                  <div className="p-2">
                    <Form
                      className="form-horizontal"
                      onSubmit={submitLogin}
                    >
                      {renderAlert()}

                        <FormGroup className="mb-3" row>
                          <Label
                            htmlFor="email"
                          >
                            {props.t("Email address")}
                          </Label>

                          <Col>
                            <Input
                              id="email"
                              name="email"
                              value={email}
                              className="form-control"
                              placeholder={props.t("Input your email")}
                              type="text"
                              required
                              onChange={(e) => setEmail(e.target.value)}
                            />
                          </Col>
                        </FormGroup>

                        <FormGroup className="mb-3" row>
                          <Label
                            htmlFor="password"
                          >
                            {props.t("Password")}
                          </Label>

                          <Col>
                            <Input
                              id="password"
                              name="password"
                              value={password}
                              className="form-control"
                              placeholder={props.t("Input your password")}
                              type="password"
                              required
                              onChange={(e) => setPassword(e.target.value)}
                            />
                          </Col>
                        </FormGroup>

                        <Row className="justify-content-end">
                          <Col lg="mt-3 d-grid">
                            {renderButton()}
                          </Col>
                        </Row>
                    </Form>
                  </div>
                </CardBody>
              </Card>

              <div className="mt-5 text-center">
                <p>
                  © {new Date().getFullYear()} {props.t("KIS. Created with")}{" "}
                  <i className="mdi mdi-heart text-danger" /> {props.t("by the KISteam")}
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
}

export default withTranslation()(Login);
