import React, { useEffect } from "react";

// Layout Related Components
import Header from "./Header";
import Sidebar from "./Sidebar";
import Footer from "./Footer";

function Layout(props) {

  const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

  useEffect(() => {
    window.scrollTo(0, 0)
  });
  
  return (
    <React.Fragment>
      <div id="layout-wrapper">
        <Header />
        <Sidebar
          theme={props.leftSideBarTheme}
          type={"default"}
          isMobile={isMobile}
        />
        <div className="main-content">{props.children}</div>
        <Footer />

      </div>
    </React.Fragment>
  );
}

export default Layout;
