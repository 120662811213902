import React, { useState } from "react"
import { axiosClient, Alerts } from "../../../../helpers";
import { withTranslation } from "react-i18next";
import { CSVReader } from 'react-papaparse'

import {
  Row,
  Col,
  Card,
  Table,
  CardBody,
  CardSubtitle,
  Container,
  CardTitle,
  Button,
} from "reactstrap"

// Breadcrumb
import Breadcrumbs from "../../../../components/common/Breadcrumb";

const UploadStocks = (propCSVs) => {
  const [csv, setCsv] = useState<any[]>([]);
  const [loader, setLoader] = useState(false)
  const [displayAlert, setDisplayAlert] = useState(false);
  const [displayAlertType, setDisplayAlertType] = useState('');
  const [alertText, setAlertText] = useState('');

  function submitUpload() {
    setLoader(true);
    axiosClient.post('/ex/inventories', {
      inventory: csv,
    })
    .then(() => {
      setLoader(false);
      setDisplayAlertType('success');
      setAlertText(('Bravo ! Le stock a bien été mis à jour pour YESSS !'));
      setDisplayAlert(true);
      setCsv([]);
    })
    .catch(() => {
      setDisplayAlertType('error');
      setAlertText(('Une erreur est survenue... Veuillez vérifier que tous les champs obligatoires sont bien présents'));
      setDisplayAlert(true);
      setLoader(false);
    })
  }

  function renderAlert() {
    if (displayAlert) {
      return (
        <div className="mt-5">
          {Alerts(displayAlertType, alertText)}
        </div>
      );
    }
  }

  function handleOnDrop(data) {
    setCsv(data);
    const a = data[0].data[2];
    console.log(escape(encodeURIComponent(escape(a))))
  }

  function handleOnError(err, file, inputElem, reason) {
    console.log(err)
  }

  function handleOnRemoveFile(data) {
    console.log('---------------------------')
    console.log(data)
    console.log('---------------------------')
  }
  
  function csvGrid() {
    function renderThead() {
      if (csv.length) {
        return csv[0].data.map((column_name, index) => {
          return <th key={index}>{column_name}</th>;
        });
      }
      return null;
    }

    function renderTContent() {
      if (csv.length) {
        const displayVersion = csv.slice(1, 100);
        return displayVersion.map((column, index) => {
          return (
            <tr key={index + 1}>
              {column.data.map((content, i) => {
                return <td key={i + 1}>{content}</td>;
              })}
            </tr>
          );
        });
      }
      return null;
    }

    function renderButton() {
      if (csv.length && !loader) {
        return (
          <Button color="success" className="mt-3" onClick={(e) => submitUpload()}>
            Mettre à jour les stocks pour YESSS
          </Button>
        )
      }
      if (csv.length && loader) {
        return (
          <div className="spinner-border text-success m-1" />
        )
      }
    }
    return (
      <Row>
        <Col lg={12} style={csv.length ? {} : { opacity: 0.4 }}>
          <Card>
            <CardBody>
              <CardTitle>Extrait de votre fichier CSV</CardTitle>
              <CardSubtitle className="mb-3">
                Pour faciliter la lecture , 100 lignes maximum de votre fichier
                sont affichées.<br/> <b>Pour valider définitivement la mise à jour des stocks pour YESSS cliquez ci-dessous.</b>
                <br />
                {renderButton()}
              </CardSubtitle>
              <div className="table-responsive">
                <Table className="table table-bordered mb-0">
                  <thead>
                    <tr>{renderThead()}</tr>
                  </thead>
                  <tbody>{renderTContent()}</tbody>
                </Table>
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
    );
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs title="IMPORTER LES STOCKS OBO - YESSS" breadcrumbItem="IMPORTER LES STOCKS OBO - YESSS" />

          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <CardSubtitle className="mb-3">
                    Pour que le fichier des stocks soit traité, il doit 
                    <span className="text-danger">
                      <b> obligatoirement être au format CSV, et comporter des colonnes avec les noms suivants : Article, Type, Désignation, Unité, Stock disponible</b>
                    </span>
                    <br/>
                    Une fois traité, les produits et stocks seront automatiquements mis à jour pour Yesss.
                  </CardSubtitle>
                  <CSVReader
                    onDrop={handleOnDrop}
                    onError={handleOnError}
                    addRemoveButton
                    config={{encoding: "ISO-8859-1"}}
                    onRemoveFile={handleOnRemoveFile}
                  >
                    <span>Cliquez ou faites glisser pour insérer votre fichier CSV ici.</span>
                  </CSVReader>
                  {renderAlert()}

                  {csvGrid()}

                  {/* <div className="text-center mt-4">
                    <button onClick={(e) => submitUpload(e)}
                      type="button"
                      className="btn btn-outline-success btn- waves-effect waves-light"
                    >
                      {props.t("Send file and update inventory")}
                    </button>
                  </div> */}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
}

export default withTranslation()(UploadStocks);