import React, { useEffect } from 'react';
import {axiosClient} from '../../helpers';

export default function IsAuthenticated() {
  const [isLoggedIn, setLoggedIn] = React.useState(false);

  useEffect(()=>{
    axiosClient.get('/ex/validate_token')
    .then((response) => {
      setLoggedIn(true);
    })
    .catch(() => {
      setLoggedIn(false)
    })
  }, [])
  
  const checkLogin = isLoggedIn
  return checkLogin;
}
