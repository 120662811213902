
import { url } from './url';
let axios = require("axios");

const uid = localStorage.getItem("uid");
const client = localStorage.getItem("client");
const accesstoken = localStorage.getItem("accesstoken");

const URL = (process.env.NODE_ENV !== 'production')? url : 'https://api.obo.getkis.link';

let axiosClient = axios.create({
  baseURL: URL,
  headers: {
    "access-token": accesstoken,
    uid: uid,
    client: client,
  },
});

export default axiosClient;
