import * as React from "react";
import { HashRouter as Router, Switch, Route } from "react-router-dom";
import { Redirect } from "react-router-dom";
import Layout from "./components/layout/layout";
import { IsAuthenticated } from './helpers';

import { 
  Login, 
  UploadStocks,
} from "./pages";

const publicRoutes = () => {
  return (
    <Router>
      <Switch>
        <Route exact path="/">
          <Login />
        </Route>
        <Route render={() => <Redirect to={{pathname: "/"}} />} />
      </Switch>
    </Router>
  )
}

const privateRoutes = () => {
  function dispatch() {
    return (
      <Router>
        <Switch>
          <Layout>
            <Route path="/">
              <UploadStocks />
            </Route>
          </Layout>
        </Switch>
      </Router>
    )
  }
  

  return dispatch();
}

function RootNavigator() {
  const checkLogin = IsAuthenticated();

  return (
    checkLogin ? privateRoutes() : publicRoutes()
  );
}

export default RootNavigator;
