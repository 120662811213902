import React, { useEffect, useRef } from "react";

// //Import Scrollbar
import SimpleBar from "simplebar-react";

// MetisMenu
// import MetisMenu from "metismenujs";
import { Link } from "react-router-dom";

//i18n
import { withTranslation } from "react-i18next";

const SidebarContent = (props: any) => {
  const ref = useRef<any>();

  // useEffect(() => {
    // const pathName = window.location.pathname;
    
    // const initMenu = () => {
    //   new MetisMenu("#side-menu");
    //   var matchingMenuItem = null;
    //   const ul = document.getElementById("side-menu");
    //   const items = ul !== null ? ul.getElementsByTagName("a") : null;
    //   if (items !== null) {
    //     for (let i = 0 ; i < items.length ; ++i) {
    //       if (pathName === items[i].pathname) {
    //         var matchingMenuItem = items[i];
    //         break;
    //       }
    //     }
    //   }
    // };
    // initMenu();
  // }, []);

  useEffect(() => {
    ref!.current.recalculate();
  });

  function getNavLinkClass(path) {
    return window.location.hash.split('#')[1] === path ? 'mm-active' : '';
  }

  const isBoss = localStorage.getItem('position') === '0' ? true : false;

  return (
    <React.Fragment>
      <SimpleBar style={{ maxHeight: "100%" }} ref={ref}>
        <div id="sidebar-menu">
          <ul className="metismenu list-unstyled" id="side-menu">
            <li className="menu-title">{props.t("Menu")}</li>
            <li className={getNavLinkClass('/')}>
              <Link to="/" className="waves-effect">
                <i className="bx bx-home-circle"></i>
                <span>{props.t("Dashboard")}</span>
              </Link>
            </li>

           

            {/* <li className={getNavLinkClass('/')}>
              <Link to="/" className="waves-effect">
                <i className="bx bx-search-alt"></i>
                <span>{props.t("Recherche détaillée")}</span>
              </Link>
            </li> */}

            {/* <li className={getNavLinkClass('/statistics')}>
              <Link to="/statistics" className="waves-effect">
                <i className="bx bx-stats"></i>
                <span>{props.t("Sales rep and product's family details")}</span>
              </Link>
            </li> */}

          
          </ul>
        </div>
      </SimpleBar>
    </React.Fragment>
  );
};

export default withTranslation()(SidebarContent);
