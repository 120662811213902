export default function Credentials(action, data) {
  if (action === 'set') {
    // LOG CREDENTIALS
    localStorage.setItem("uid", data.header.uid);
    localStorage.setItem("client", data.header.client);
    localStorage.setItem("accesstoken", data.header.token);
    // USER DATA
    localStorage.setItem("email", data.body.data.email);
    localStorage.setItem("position", data.body.data.position);
    localStorage.setItem("firstname", data.body.data.first_name);
    localStorage.setItem("lastname", data.body.data.last_name);
    localStorage.setItem("id", data.body.data.obo_id);
  } 

  if (action === 'clear') {
    localStorage.removeItem("uid");
    localStorage.removeItem("client");
    localStorage.removeItem("accesstoken");

    localStorage.removeItem("position");
    localStorage.removeItem("email");
    localStorage.removeItem("firstname");
    localStorage.removeItem("lastname");
    localStorage.removeItem("id");
  }
  return window.location.href ='/';
}